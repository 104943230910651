var closeButtonActive = false
var fadeState = true

$(document).ready(function () {
  $(".jobs__item").on("click", function () {
    if ($(this).hasClass("is-active") && closeButtonActive == true && fadeState == true) {
      $(this).removeClass("is-active")
      closeButtonActive = false
      fadeState = false
    } else {
      $(".jobs__item").removeClass("is-active")
      $(this).removeClass("is-active")
      $(this).addClass("is-active")
      closeButtonActive = false
      $(this).children(".jobs__detail").css("animation", "fade-in 0.5s ease-out")
      fadeState = false
    }
  })

  $(document).on("click", ".jobs__detailIcon", function () {
    $(".jobs__item").removeClass("is-active")
    closeButtonActive = true
    $(".jobs__detail").addClass("is-active")
    $(this).parent().css("animation", "fade-out 0.8s ease-out")
    fadeState = false
  })
})
