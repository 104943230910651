$(document).ready(function () {
  //
  var controllerAnker = new ScrollMagic.Controller();

  var scene1 = new ScrollMagic.Scene({
    triggerElement: "#desktop-anker-trigger",
    triggerHook: 0,
  })
    //.addIndicators()

    .addTo(controllerAnker);

  scene1.on("start", function (event) {
    $(".anker-bar").toggleClass("anker-bar--scrolled");
  });
});
