$(document).ready(function () { 
    var controller = new ScrollMagic.Controller();

    var scene1 = new ScrollMagic.Scene({
        triggerElement: '#header-trigger',
        triggerHook: 0
    })
    //.addIndicators()

    .addTo(controller);

    scene1.on("start", function (event) {
        $('.header').toggleClass('header--scrolled')
    }) 
})