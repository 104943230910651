$(document).ready(function () {
  $.ajax({
    url: "https://xmas.digital-workbench.eu/get_images.php",
    success: function (result) {
      let images = JSON.parse(result)
      $.each(images, function (item, value) {
        let galleryItem = document.createElement("div")
        galleryItem.setAttribute("id", "image-" + value.id)
        galleryItem.setAttribute("class", "xmas-gallery__item")
        galleryItem.setAttribute("data-autor", value.name)
        galleryItem.setAttribute("data-likes", value.likes)
        galleryItem.setAttribute("data-id", value.id)
        galleryItem.setAttribute("data-liked", false)
        galleryItem.innerHTML =
          '<img src="https://xmas.digital-workbench.eu/xmas_gallery/' +
          value.file +
          '" class="xmas-gallery__item--img" />' +
          '<div class="xmas-gallery__detail">' +
          '<div class="xmas-icon xmas-icon--plus"></div></div>' +
          '<div class="xmas-gallery__likes">' +
          '<i class="xmas-gallery__likes--icon"></i>' +
          '<span class="xmas-gallery__likes--counter">' + value.likes + '</span></div>'+
          '</div>'
        document.getElementById("xmas-gallery").append(galleryItem)
      })
      // init Masonry - xmas gallery layout
      FlexMasonry.init(".xmas-gallery", {
        responsive: 1,
        breakpointCols: {
          "min-width: 1600px": 6,
          "min-width: 1260px": 5,
          "min-width: 768px": 4,
          "min-width: 420px": 3,
          "min-width: 360px": 2,
        },
      })
    },
  })

  $("#submit").click(function (event) {
    // Stop form from submitting normally
    event.preventDefault()
    var form = $("#form")[0]
    var response = $(".response")[0]
    var data = new FormData(form)
    $("#submit").prop("disabled", true)
    $.ajax({
      type: "POST",
      enctype: "multipart/form-data",
      url: "https://xmas.digital-workbench.eu/upload.php",
      data: data,
      processData: false,
      contentType: false,
      success: function (result) {
        let data = JSON.parse(result)
        console.log(data)
        if (data.successful == false) {
          $(response).text(data.error)
          $(response).show()
        } else {
          $(response).addClass("is-active")
          $(".submit").addClass("is-active")
          $(response).text(data.message)
          $(response).show()

          //add new item to gallery
          let galleryItem = document.createElement("div")
          galleryItem.setAttribute("class", "xmas-gallery__item")
          galleryItem.setAttribute("class", "flexmasonry-item")
          galleryItem.setAttribute("data-autor", data.data.autor)
          galleryItem.setAttribute("data-likes", 0)
          galleryItem.setAttribute("data-id", data.data.id)
          galleryItem.setAttribute("data-liked", false)
          galleryItem.innerHTML =
            '<img src="https://xmas.digital-workbench.eu/xmas_gallery/' +
            data.data.file +
            '" class="xmas-gallery__item--img" />' +
            '<div class="xmas-gallery__detail">' +
            '<div class="xmas-icon xmas-icon--plus"></div></div>' +
            '<div class="xmas-gallery__likes">' +
            '<i class="xmas-gallery__likes--icon"></i>' +
            '<span class="xmas-gallery__likes--counter">0</span></div>'+
            '</div>'
          document.getElementById("xmas-gallery").append(galleryItem)
        }
      },
    })
  })

  //likebutton
  $(".xmas-overlay-like__icon").click(function () {
    var itemid = $(this).parent().attr("data-id")
    var liked = $(this).parent().attr("data-liked")

    // read cookie
    var likeids = readCookie("likes")

    if (likeids && likeids != "") {
      likeids.split(",")
    } else {
      likeids = new Array()
    }

    if (liked != true && likeids.includes(itemid) != true) {
      // Bild wurde noch nicht geliked
      $(this).addClass("is-active")
      $.ajax({
        type: "POST",
        url: "https://xmas.digital-workbench.eu/post_likes.php?id=" + itemid,
        processData: false,
        contentType: false,
        success: function (result) {
          result = JSON.parse(result)
          if (result.successful) {
            $(".xmas-overlay-like__counter").text(parseInt($(".xmas-overlay-like__counter").text()) + 1)
            $("#image-" + itemid + " .xmas-gallery__likes--counter").text(parseInt($("#image-" + itemid + " .xmas-gallery__likes--counter").text()) + 1)
      
           
            // Data Attribut in Galerie anpassen
            $("#image-" + itemid).attr("data-liked", "true")
            $("#image-" + itemid).attr("data-likes", $("#image-" + itemid).data("likes") + 1)

            likeids.toString()
            if (likeids && likeids != "") {
              // current values + new item id
              likeids += "," + itemid
              document.cookie = "likes=" + likeids
            } else {
              document.cookie = "likes=" + itemid
            }
          }
        },
      })
    }
  })
})

function readCookie(cName) {
  const name = cName + "="
  const cDecoded = decodeURIComponent(document.cookie)
  const cArr = cDecoded.split("; ")
  let res
  cArr.forEach((val) => {
    if (val.indexOf(name) == 0) res = val.substring(name.length)
  })
  return res
}
