$(document).ready(function () {

    ////// HÖHE DES VIEPORTS BESTIMMEN
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh-stage', `${vh}px`);
    // OHNE RESIZE BEI SCROLL, deshalb auskommentieet
     $(window).on('resize', function () {
         if(checkWidth() > 800) {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh-stage', `${vh}px`);
         }
       
    }); 


    function checkWidth() {
        var windowsize = $(window).width();
        return windowsize
      }
})


