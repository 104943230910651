$(document).ready(function () {
  initProjectSlider()
});

function initProjectSlider() {
  $(".project__slider").each((key, item) => {
    $(item).slick({
      infinite: false,
      slidesToShow: 1.4,
      variableWidth: true
    })
  })
}