$(document).ready(function () {
  $(".team-grid__item").click(function () {
    $(this).toggleClass("team-grid__item--active")
  })

  $(document).on("scroll", function () {
    if ($(window).width() < 960) {
      let scrollPosition = $(document).scrollTop()
      let windowHeight = $(window).height()
      $(".team-grid__avatar").each(function (index, member) {
        if (!$(this).hasClass("topout")) {
          if (
            $(this).offset().top < scrollPosition + windowHeight - 170 &&
            $(this).offset().top > scrollPosition + 30
          ) {
            $(this).css("transform", "translateY(5%)")
          } else {
            $(this).css("transform", "translateY(100%)")
            $(this).addClass("topout")
          }
        } else {
          if (
            $(this).offset().top < scrollPosition + windowHeight - 170 &&
            $(this).offset().top > scrollPosition + 200
          ) {
            $(this).css("transform", "translateY(5%)")
            $(this).removeClass("topout")
          } else {
            $(this).css("transform", "translateY(100%)")
          }
        }
      })
    }
  })
})
