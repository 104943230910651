$(document).ready(function () {
  $("#file").on("change", function () {
    let input = document.querySelector("#file")
    let files = input.files

    // alte Liste entfernen
    $(".file-field div").remove()

    // neue Liste einfügen
    let list = document.createElement("div")
    $(".file-field").append(list)

    $.each(files, function (index) {
      let item = document.createElement("p")
      item.innerHTML = files[index].name
      // console.log(files[index].name)
      list.append(item)
    })
    $(".file-label").addClass("is-active")
  })
})
