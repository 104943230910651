$(document).ready(function () {
  // formular
  $(document).on("click", ".xmas-icon--upload", openXmasUpload)
  $(document).on("click", ".xmas-icon--close", closeXmasUpload)

  $("#bild").on("change", function () {
    $(".xmas-stage__bild").text($("#bild").val().replace("C:\\fakepath\\", ""))
  })

  // overlay öffnen
  if ($(window).width() < 660) {
    $(document).on("click", ".xmas-gallery__item--img", showOverlay)
  } else {
    $(document).on("click", ".xmas-gallery__detail", showOverlay)
    $(document).on("click", ".xmas-gallery__item--img", showOverlay)
  }

  // overlay schließen
  $(".xmas-overlay, .xmas-overlay-close").on("click", function (e) {
    if (e.target == this) {
      $(".xmas-overlay-like__icon").removeClass("is-active")
      $("body").removeClass("no-body-scrolling")
      $(".xmas-overlay").removeClass("show-lightbox");
    }
  })
})

function openXmasUpload() {
  if (!$(".xmas-stage__container").hasClass("is-active")) {
    $(".xmas-stage__container").addClass("is-active")
    $(".xmas-icon--close").fadeIn("slow")
    $(".xmas-icon--upload").fadeOut("fast")
  }
}

function closeXmasUpload() {
  $(".xmas-stage__container").removeClass("is-active")
  $(".xmas-icon--upload").fadeIn("slow")
  $(".xmas-icon--close").fadeOut("fast")
}

function showOverlay() {
  $(".xmas-overlay").addClass("show-lightbox");
  $("body").addClass("no-body-scrolling")

  var autor = $(this).parent().attr("data-autor")
  var likes = $(this).parent().attr("data-likes")
  var id = $(this).parent().attr("data-id")
  var liked = $(this).parent().attr("data-liked")

  if ($(this).hasClass("xmas-gallery__detail")) {
    var bild = $(this).prev().attr("src")
  } else {
    var bild = $(this).attr("src")
  }

  $(".xmas-overlay-image").attr("src", bild)

  if (autor != "") {
    $(".xmas-overlay-author").text("von " + autor)
  }

  //likes aus der datenbank anzeigen
  $(".xmas-overlay-like__counter").text(likes)

  //data-attribute ins overlay item übernehmen
  $(".xmas-overlay-like").attr("data-id", id)
  $(".xmas-overlay-like").attr("data-liked", liked)

  if (liked == "true") {
    $(".xmas-overlay-like__icon").addClass("is-active")
  } else {
    $(".xmas-overlay-like__icon").removeClass("is-active")
  }
}
