$(document).ready(function () {
    $('.stage__slider').slick({
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 800,
        pauseOnHover: false,
        fade: true,
        arrows: true,
        prevArrow: $('.stage__slider-button--prev'),
        nextArrow: $('.stage__slider-button--next'),

    })
})