$(document).ready(function () {
  //
  var controller = new ScrollMagic.Controller();

  var scene1 = new ScrollMagic.Scene({
    triggerElement: "#anker-trigger",
    triggerHook: 0,
  })
    //.addIndicators()

    .addTo(controller);

  scene1.on("start", function (event) {
    $(".anker-select").toggleClass("anker-select--scrolled");
  });

  $(".anker-select__selected").click(function (e) {
    e.stopPropagation();
    if ($(this).closest(".anker-select").hasClass("anker-select--active")) {
      closeSelect();
    } else {
      openSelect();
    }
  });

  $(document).click(function () {
    closeSelect();
  });

  function openSelect() {
    $(".anker-select").addClass("anker-select--active");
    $(".anker-select__container").slideDown();
  }

  function closeSelect() {
    $(".anker-select").removeClass("anker-select--active");
    $(".anker-select__container").slideUp();
  }
});
