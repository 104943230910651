$(document).ready(function () {
    $('.kennenlernen-stage__slider').slick({
        autoplay: true,
        autoplaySpeed: 4000,

        speed: 800,
        pauseOnHover: false,
        arrows: true,
        prevArrow: $('.kennenlernen-stage__slider-button--prev'),
        nextArrow: $('.kennenlernen-stage__slider-button--next'),

    })
    var $caption = $('#stage-slider-title')
    $caption.text($(`.kennenlernen-stage__item`).eq(1).data('my-caption'))
    $(document).on('init reInit afterChange', '.kennenlernen-stage__slider', function (event, slick, currentSlide, nextSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = $(slick)[0].currentSlide + 1;
        var caption = $(`.kennenlernen-stage__item`).eq(i).data('my-caption')
        if (caption == undefined) {
            $caption.hide()
        } else {
            $caption.show()
            $caption.text(caption);
        }

    });
})