$(document).ready(function () {
  $("#nav-burger").click(function () {
    if ($(this).hasClass("--active")) {
      closeNavi();
    } else {
      openNavi();
    }
  });

  function openNavi() {
    $("#nav-burger").addClass("--active");
    $(".header, .header-verstehen, .header-kennenlernen, .header-ansehen, .header-kontaktieren, .navigation").addClass("--active");
    $(".navi").fadeIn("fast");
  }

  function closeNavi() {
    $("#nav-burger").removeClass("--active");
    $(".header, .header-verstehen,  .header-kennenlernen, .header-ansehen, .header-kontaktieren, .navigation").removeClass(
      "--active"
    );
    $(".navi").fadeOut("fast");
  }

  //VIEWPORT HÖHE
  ////// HÖHE DES VIEPORTS BESTIMMEN
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh-navi", `${vh}px`);
  // OHNE RESIZE BEI SCROLL, deshalb auskommentieet
  $(window).on("resize", function () {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh-navi", `${vh}px`);
  });
});
