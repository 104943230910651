$(document).ready(function () {
    $('#contact--da').click(function (e) { 
        e.preventDefault();
        $('.contact--selected').removeClass('contact--selected');
        $('.contact--da').addClass('contact--selected');
    });

    $('#contact--s').click(function (e) {
        e.preventDefault();
        $('.contact--selected').removeClass('contact--selected');
        $('.contact--s').addClass('contact--selected');
    });
});