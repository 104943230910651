$(document).ready(function () { 

    $(document).on("scroll", function() {
        var sections = $('.anker-target');
        var currentSection = '';
        for (let i = 0; i < sections.length; i++) {
            const element = sections[i];
            
            var position = $(element).position();
            var top = position.top;
            
            var currentScrollPosition = $(document).scrollTop();
            
            if (currentScrollPosition > top) {
                console.log($(element).attr('id'));
                currentSection = $(element).attr('id');
            }
        }

        $('.anker-bar__item').removeClass('anker-bar__item--active');
        $('#anker-item-'+currentSection).addClass('anker-bar__item--active');
    });
})
