
  $(document).ready(function () {
  // WIDTH FUNCION
  function checkWidth() {
    var windowsize = $(window).width();
    return windowsize
  }

//SCROLLMAGIC

  ////FADEIN
  var ctrl = new ScrollMagic.Controller();
  var fadeTrigger // Trigger unterchiedlich nach Breakpoint
  if (checkWidth() < 960) {
    fadeTrigger = 1;
  } else {
    fadeTrigger = 0.8
  }

  $(".fadein").each(function (i) {
    var fadeElement = $(this)
    var tl = gsap.timeline();

    tl.fromTo(fadeElement, {
      opacity: 0,
      y: 20
    }, {
      opacity: 1,
      y: 0
    })

    new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: fadeTrigger,
        reverse: true
      })
      //.addIndicators()
      .setTween(tl.duration(.4))
      .addTo(ctrl);
  });

  var staggTrigger // Trigger unterchiedlich nach Breakpoint
  if (checkWidth() < 960) {
    staggTrigger = .9;
  } else {
    staggTrigger = 0.7
  }
  ////STAGGFADE
  $(".staggfade").each(function (i) {
    var staggElements = $(this).children('*')
    var tl = gsap.timeline();

    tl.fromTo(staggElements, {
      opacity: 0,
      y: 20
    }, {
      opacity: 1,
      y: 0,
      duration: .5,
      stagger: {
        amount: .5,
        from: "lefttop",
      }
    });

    new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: staggTrigger,
        reverse: true
      })
      .setTween(tl.duration(1))
      /*          .addIndicators() 
       */
      .addTo(ctrl);
  });


})